import React from 'react'
import {FunctionComponent} from 'react'
const HomePage = (): FunctionComponent => {

  return (
    <div className="mx-auto w-full flex flex-col items-center text-center">
      <img src="https://mokajandek.hu/wp-content/uploads/2021/08/forwho-balloon.png" width="200" className="mb-4" />
      <h1 className="text-black font-bold text-2xl mb-4">Kedves vásárlóink!</h1>
      <p className="mb-4">
        Mókajándékunk bezárta a kapuit. <br />
        Köszönjük a sok szeretetet és támogatást, amit az évek során kaptunk tőletek. <br />
        Köszönjük, hogy játékosságunkkal egy kis örömöt csempészhettünk a hétköznapjaitokba 🙂 <br />
      </p>
      <p>
        Szeretettel Balázs és Melinda
      </p>
    </div>
  )
}

export default HomePage